import React from "react"
import Layout from "../../../components/layout"
import Hero from "../../../components/olympo/hero"
import WrapperBlock from "../../../components/olympo/wrapperBlock"
import img from "../../../assets/images/heroOlympo36.png"
import { OlympoBlock1 } from "../../../data/olympo"
import { OlympoBlock2 } from "../../../data/olympo"
import { OlympoBlock3 } from "../../../data/olympo"
import { OlympoBlock4 } from "../../../data/olympo"
import { OlympoBlock5 } from "../../../data/olympo"
import Block1 from "../../../components/olympo/block1"
import Block2 from "../../../components/olympo/block2"
import Contact from "../../../components/olympo/contact"
const olympo325 = () => {
  return (
    <Layout>
      <Hero
        img={img}
        title="OLYMPO 3.6"
        subtitle="Buses para personal de lima"
        text="Vehículo especial para transportar PERSONAL DE MINA. Diseñado y equipado para RECORRIDOS DE DISTANCIAS MEDIANAS  Y LARGAS. Carrocería ROBUSTO, COMPACTO, SALÓN PASAJEROS ESPACIOSO,  SEGURO Y ELEGANTE."
        crTitle1="Pasajeros sentados"
        crTitle2="Largo"
        crTitle3="Ancho"
        crTitle4="Altura"
        crTitle5="Chasis"
        crText1="37"
        crText2="10.00 MTS"
        crText3="2.40 MTS"
        crText4="3.25 MTS"
        crText5="HINO, MERCEDES BENZ, AGRALE"
      />
      <WrapperBlock>
        <Block1 subtitle="SALÓN DE PASAJEROS" data={OlympoBlock1}></Block1>
        <Block2 subtitle="CABINA DE CONDUCTOR" data={OlympoBlock2}></Block2>
        <Block1 subtitle="CONDUCCIÓN" data={OlympoBlock5}></Block1>
        <Block1 subtitle="EXTERIOR" data={OlympoBlock3}></Block1>
        <Block2 color="gris" data={OlympoBlock4}></Block2>
      </WrapperBlock>
      <Contact></Contact>
    </Layout>
  )
}

export default olympo325
